<template>
  <section>
    <b-row>
      <b-col>
        <b-card class="mb-2">
          <div v-if="!infoBot">
            <h4>Aqui puedes enlazar tu cuenta de WhatsApp con el bot.</h4>
            <b-col class="d-flex justify-content-center mt-4">
              <b-card border-variant="primary" class="text-center">
                <p>
                  Escanea el código QR para enlazar tu cuenta de WhatsApp con el
                  bot.
                </p>
                <img :src="urlImageQr" alt="QR" width="300" height="300" />
              </b-card>
            </b-col>
          </div>

          <b-col v-if="infoBot" class="d-flex justify-content-center mt-4">
            <b-card border-variant="success">
              <p>Tu sesión de WhatsApp está activa.</p>
              <b-col v-if="infoBot.name">
                <b>Nombre: </b>
                <span>{{ infoBot.name }}</span>
              </b-col>
              <b-col v-if="infoBot.phone">
                <b>Número: </b>
                <span>+{{ infoBot.phone }}</span>
              </b-col>
            </b-card>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  data() {
    return {
      isinfoBot: false,
      loading: false,
      urlImageQr: process.env.VUE_APP_URL_API_BOT + "scan-qr",
      infoBot: {},
      intervalId: null,
    };
  },
  methods: {
    async configBot() {
      try {
        const configBot = await this.$store.dispatch(
          "botWhatsApp/getConfigBot"
        );
        this.infoBot = configBot?.infoBot || null;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.configBot();
    this.intervalId = setInterval(() => {
      this.configBot();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
